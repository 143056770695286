.repoCard{
    background-color: #FEFAE0;
    border-radius: 20px;
    height: 180px;
    width: 350px;
    padding: 20px;
   
    display: flex;
    flex-direction: column;
    gap: 10px;

    transition: 0.3s;
}

.repoCard:hover{
    transform: scale(1.05);
    filter:brightness(0.97) drop-shadow(2px 2px 3px);
}

.repoCard *{
    color: black;
    font-family: 'Work Sans', sans-serif;
}

.repoCard h1{
    color:#606c38;
}

.repoCard p{
    font-size: 18px;
    font-weight: 500;
}

.repoCard .dates{
    display: flex;
    align-items: end;
    justify-content: space-between;

    flex:1;
}

.repoCard h2{
    font-weight: 400;
    font-size: 14px;
}

.repoCard em{
    font-weight: 500;
    font-size: 16px;
}