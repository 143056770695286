.header{
    background-color: #606C38;
    box-sizing: border-box;
    padding: 0 20px 0 20px; 
    width: 100%;
    height: 65px;

    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
}

.header *{
    font-family: 'Work Sans', sans-serif;
}

.header>*{
    flex: 1;
}

.headerLinks{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 25px;

    height: 40px;
    width: 100%;
}

.icon, .resume{
    transition: 0.3s;
}

.icon:hover{
    transform: scale(1.1);
    filter: invert();
}

.resume:hover{
    transform: scale(1.05);
    
}

.header>p{
    
    font-size: 24px;
    font-weight: 600;
    color: white;

}

.header .resume{

    justify-self: center;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

.header .resume>p{
    color:white;
    font-size: 18px;
    font-weight: 400;
}

@media only screen and (max-width: 768px){
    .mobileHide{
        display: none;
    }
}