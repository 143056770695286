.footer{
    align-items: center;
    justify-items: flex-start;
    background-color: #bc6c25;
    box-sizing: border-box;
    display: flex;

    gap: 3vw;
    padding: 10vh 10vw 10vh 10vw;
    width: 100%;

}

.footer>*{
    min-width: 150px;
}

.footer ul{
    display: flex;
    flex-direction: column;
}

.footer *{
    color: white;
    font-family: 'Work Sans', sans-serif;
    line-height: 1.5;

    font-weight:400;
    font-size: 16px;
}

.footer img{
    width: 120px;
}

.footer h1{
    font-weight:600;
    font-size: 24px;
}

.footer li, .footer a{
    transition: 0.3s;
}

.footer li:hover{
    transform: scale(1.1);
}

.footer a:hover{
    color: lightgray;
}

.footer .note{
    font-size: 14px;
    font-weight: 300;

    max-width: 40%;
    justify-self: flex-end;
    margin-left: auto;
}

.signature{
    font-size: 16px;

    text-align: end;
}

@media only screen and (max-width: 768px){
    .footer{
        flex-direction: column;
        justify-items: flex-start;
        padding: 10vh 5vw 10vh 5vw;
        gap: 5vh
    }

    .footer div, .footer .note{
        margin: auto;
        max-width: 90%;
    }
}